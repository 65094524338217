<template>
  <div class="DefaultSnackbarComponentWrapper">
    <div class="visual">
      <img v-if="picture" class="picture" :src="picture" alt="" />
      <i v-else-if="icon" class="icon" :class="icon" />
    </div>
    <div class="content">
      <span v-if="title" class="title preWrap" v-html="title" />
      <span v-if="text" class="text preWrap" v-html="text" />
      <slot name="text" />
      <div v-if="linkRight" class="tw-flex-grow" />
      <NuxtLinkLocale
        v-if="link"
        class="link"
        :to="{ path: '/' + link, query }"
        @click="onClick"
      >
        {{ linkPlaceholder ? linkPlaceholder : $t(`shared.snackbar.${link}`) }}
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type {
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
} from 'vue-router'

export default defineComponent({
  props: {
    picture: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    link: {
      type: [String, Object] as PropType<string | RouteLocationRaw>,
      required: false,
      default: '',
    },
    linkRight: {
      type: Boolean,
      required: false,
      default: false,
    },
    linkPlaceholder: {
      type: String,
      required: false,
      default: '',
    },
    onLinkClick: {
      type: Function,
      required: false,

      default: () => {},
    },
    query: {
      type: Object as PropType<RouteLocationNormalizedLoaded['query']>,
      required: false,
      default: null,
    },
    influencerName: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
  emits: ['use'],
  methods: {
    emitUserEvent: function () {
      this.$emit('use')
    },
    onClick() {
      this.emitUserEvent()
      if (this.onLinkClick) this.onLinkClick()
    },
  },
})
</script>

<style scoped lang="scss">
.DefaultSnackbarComponentWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.error .DefaultSnackbarComponentWrapper.title {
  color: var(--color-error);
}

.visual {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.icon,
.picture {
  margin-right: 18px;
}

.picture {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}

.icon {
  @apply tw-text-sm;
  color: var(--color-white);
}

.title {
  font-size: var(--font-size-title-nano);
  font-weight: theme('fontWeights.bold');
  color: var(--color-white);
  margin-right: 4px;
}

.text {
  font-size: var(--font-size-big);
  font-weight: theme('fontWeights.semibold');
  color: var(--color-white);
  margin-right: 4px;
}

.link {
  @apply tw-text-orange-400;
  font-size: var(--font-size-big);
  font-weight: theme('fontWeights.semibold');
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .title {
    font-size: var(--font-size-micro);
    font-weight: theme('fontWeights.bold');
    color: var(--color-white);
    margin-right: 4px;
    word-break: break-word;
  }

  .text {
    font-size: var(--font-size-micro);
    font-weight: theme('fontWeights.semibold');
    color: var(--color-white);
    margin-right: 4px;
    word-break: break-word;
  }

  .link {
    font-size: var(--font-size-micro);
    font-weight: theme('fontWeights.semibold');
    color: var(--color-main);
    text-decoration: underline;
  }
}
</style>
